<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="8" class="d-flex">
          <img
            src="@/assets/icons/ocr.png"
            height="22"
            width="22"
            alt="age-icon"
          />
          <div class="main-heading">
            Document OCR
            <v-icon class="info-icon" v-if="!alert" @click="alert = true"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
        <v-col cols="4" class="text-end">
          <p v-if="!isLoading">
            Remaining Requests:
            <span
              class="fw-600"
              :class="{
                'darkColor--text': remainingCount >= 6,
                'orange--text': remainingCount <= 5 && remainingCount >= 4,
                'red--text': remainingCount <= 3,
              }"
              >{{ remainingCount }}</span
            >
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-alert v-model="alert" close-text="Close Alert" color="age-alert">
            <template v-slot:append>
              <img
                @click="alert = false"
                src="@/assets/icons/close-icon.svg"
                height="22"
                width="22"
                class="cursor-pointer"
                alt="close-icon"
              />
            </template>
            Empowering Global Connectivity through State-of-the-Art OCR
            Solutions: With our expansive reach spanning continents, from
            bustling metropolises to remote villages, we revolutionize document
            digitization. Seamlessly integrating OCR technology worldwide, we
            enhance accessibility and streamline operations for businesses of
            all scales.
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="isLoading">
        <v-col cols="12" class="no-record">
          <v-progress-circular
            :width="3"
            color="#173dd0"
            indeterminate
          ></v-progress-circular>
          <span class="pl-2"> Loading...</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-if="jsonData" cols="12">
          <div class="result-btns float-right">
            <v-btn @click="retry" depressed class="try-more">
              <span class="px-1">Try More</span>
              <img
                src="@/assets/icons/retry-icon.svg"
                height="12"
                width="12"
                alt="next-arrow"
              />
            </v-btn>
          </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
          <p class="sub-heading">Provide Details</p>
          <div class="d-flex justify-center ma-5">
            <v-card
              class="age-verification-card"
              width="640"
              style="height: 100%"
              :class="remainingCount !== 0 ? '' : 'opacity-down'"
            >
              <div class="step-retry">
                <p
                  v-if="imageSrc && showRetry"
                  class="card-retry cursor-pointer"
                  @click="imageSrc = null"
                >
                  Retry
                  <img
                    src="@/assets/icons/retry-blue.svg"
                    height="12"
                    width="12"
                    alt="retry"
                    class="ml-1"
                  />
                </p>
              </div>
              <v-card-title class="card-title"> Document </v-card-title>
              <v-card-text
                class="card-body"
                :class="
                  $vuetify.breakpoint.smAndDown && imageSrc
                    ? 'card-body-mobile'
                    : ''
                "
              >
                <div v-if="!imageSrc" class="selfie-upload">
                  <div class="option">
                    <div class="option-child" @click="takeSelfie()">
                      <img
                        src="@/assets/icons/camera-icon.svg"
                        height="20"
                        width="20"
                        alt="camera-icon"
                      />
                      <span class="option-text">Capture Document</span>
                    </div>
                    <v-divider class="vertical-divider" vertical></v-divider>
                    <div class="option-child" @click="browseClick()">
                      <img
                        src="@/assets/icons/file-upload.svg"
                        height="20"
                        width="20"
                        alt="file-upload-icon"
                      />
                      <div class="option-text">Upload Document</div>
                    </div>
                  </div>
                  <div cols="6" class="file-limit-text">Maximum size 3 MB</div>
                </div>
                <div v-if="!imageSrc" class="document-type">
                  <p class="file-limit-text mb-0">
                    <span class="fw-500">Allowed Document Type:</span> ID Card,
                    Passport, Driving License
                  </p>
                </div>
                <v-row v-if="imageSrc" style="width: 100%">
                  <v-col cols="12" class="pa-0">
                    <img
                      class="preview-img"
                      :class="
                        $vuetify.breakpoint.xsOnly ? 'preview-img-mobile' : ''
                      "
                      id="image-preview"
                      :src="imageSrc"
                      alt=""
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div v-if="hideSubmit" class="result-btns button-section mx-5">
            <p class="font-13 fw-600 red--text">
              <span v-if="remainingCount == 0"
                >Verification Limit Exceeded</span
              >
            </p>
            <v-btn
              depressed
              class="try-more"
              :disabled="validatePayload"
              @click="submitData"
              :loading="isProcessing"
              ><span class="px-1">Submit</span></v-btn
            >
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          cols="1"
          class="text-center"
        >
          <v-divider vertical class="py-5"></v-divider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <p class="sub-heading">OCR Result</p>
          <v-card class="ocr-result-card">
            <div v-if="jsonData">
              <json-tree :data="jsonData"></json-tree>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(jsonData)"
                    class="copy-icon"
                    src="@/assets/icons/copy.png"
                    width="20"
                  ></v-img>
                </template>
                <span>{{ messageCopy }}</span>
              </v-tooltip>
            </div>
            <div v-if="jsonData == null && resultLoader" class="loader-div">
              <span class="loader">
                <v-progress-circular :size="60" color="primary" indeterminate>
                </v-progress-circular>
                Fetching Results...</span
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <Camera
        v-if="startCamera && remainingCount !== 0"
        @attached-image="getImage"
        @close-camera-dialog="closeCamera"
      />
      <input
        v-if="remainingCount !== 0"
        ref="file-uploader"
        id="upload-file"
        class="d-none"
        type="file"
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="onFileChanged($event)"
      />
    </v-container>
  </div>
</template>
  
  <script>
import Camera from "../common/Camera.vue";
import ApiService from "../../services/Api";
import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";
import { API_URL } from "@/utils/env.js";
import countries from "../../constants/countries.json";
import JsonTree from "vue-json-tree";
export default {
  name: "DocumentOCR",
  components: { Camera, JsonTree },
  data() {
    return {
      startCamera: false,
      isProcessing: false,
      isLoading: false,
      showRetry: false,
      searchResultInterval: false,
      setTimeoutInterval: null,
      baseUrl: API_URL,
      clientSecret: null,
      alert: true,
      imageSrc: null,
      fileSizeLimit: 3 * 1024 * 1024,
      referenceId: null,
      disabledBtn: true,
      country: null,
      document: null,
      documentTypes: [
        { text: "ID Card", value: "id_card" },
        { text: "Passport", value: "passport" },
        { text: "Driving License", value: "driving_license" },
      ],
      messageCopy: "Click to Copy",
      jsonData: null,
      resultLoader: false,
      hideSubmit: true,
      remainingCount: 0,
    };
  },
  computed: {
    getCountriesList() {
      return countries;
    },
    validatePayload() {
      if (this.imageSrc != null && this.remainingCount !== 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getClientSecret();
  },
  methods: {
    /**
     * @description Copies the provided string to the clipboard
     * @param {string} key - The string to be copied
     */
    copyToClipboard(key) {
      // Function to copy JSON data to clipboard
      const jsonString = JSON.stringify(key, null, 2);

      // Copy JSON string to clipboard
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          this.messageCopy = "Copied!";
          // Optionally, you can show a success message
        })
        .catch((err) => {
          console.error("Failed to copy JSON to clipboard:", err);
          // Optionally, you can show an error message
        });
      setTimeout(() => {
        this.messageCopy = "Click to Copy";
      }, 1000);
    },

    /**
     * Activates the camera to take a selfie.
     */
    takeSelfie() {
      this.startCamera = true;
    },

    /**
     * Retrieves the image data after taking a selfie.
     * @param {Object} data - The image data containing base64Image and file.
     */
    getImage(data) {
      this.imageSrc = data.base64Image;
      this.selfieCameraBlob = data.file;
      this.showRetry = true;
    },

    /**
     * @Description
     *  Retrieves the client secret and remaining document OCR count from the server.
     * @param none
     * @return none
     **/
    getClientSecret() {
      this.isLoading = true;
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientSecret = res.data.result.data.client_secret;
          ApiService.PostRequest(
            "transaction/document-ocr-count",
            {},
            "iframe",
            this.clientSecret
          )
            .then((response) => {
              this.remainingCount = response.data.result.data.document_count;
              this.isLoading = false;
            })
            .catch((error) => {
              showErrorMessage(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * @Description Submits data for processing.
     * Initiates a series of API requests to process the submitted image data and retrieve results.
     * @param none
     * @return none
     **/
    submitData() {
      this.showRetry = false;
      this.isProcessing = true;
      let data = {
        type: "document_ocr",
        file: this.imageSrc,
      };
      ApiService.PostRequest(
        "transaction/create-ocr-transaction",
        data,
        "iframe",
        this.clientSecret
      )
        .then((res) => {
          this.searchResultInterval = setInterval(() => {
            this.fetchResults(res.data.result.data.reference_id);
          }, 2000);
          this.stopInterval();
          this.resultLoader = true;
          this.isProcessing = false;
          this.hideSubmit = false;
        })
        .catch((error) => {
          showErrorMessage(error);
          this.isProcessing = false;
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * @Description Fetches results from the server.
     * Makes an API request to retrieve OCR results based on the provided reference ID.
     * @param referenceId
     * @return none
     **/
    fetchResults(referenceId) {
      ApiService.PostRequest(
        "transaction/document-ocr-result",
        {
          reference_id: referenceId,
        },
        "iframe",
        this.clientSecret
      )
        .then((res) => {
          if (res.data.result.data.ocr_results !== null) {
            clearInterval(this.searchResultInterval);
            clearTimeout(this.setTimeoutInterval);
            this.jsonData = res.data.result.data.ocr_results;
            this.resultLoader = false;
          }
        })
        .catch((error) => {
          showErrorMessage(error);
          clearInterval(this.searchResultInterval);
          clearTimeout(this.setTimeoutInterval);
          this.resultLoader = false;
        });
    },

    /**
     * @Description
     * 1. Set a timeout to stop the interval after 1 minutes (60000 milliseconds)
     * @param none
     * @return none
     **/
    stopInterval() {
      this.setTimeoutInterval = setTimeout(() => {
        clearInterval(this.searchResultInterval);
        this.resultLoader = false;
        showSimpleErrorMessage("Unable to Extract the Data.");
        setTimeout(this.retry.bind(this), 2500);
      }, 60000);
    },

    /**
     * Reloads the window to retry taking a selfie.
     */
    retry() {
      window.location.reload();
    },

    /**
     * Closes the camera interface.
     */
    closeCamera() {
      this.startCamera = false;
    },

    /**
     * @Description
     * 1. this method is used to initialize the browse click
     * @param none
     * @return none
     **/
    browseClick() {
      this.$refs?.["file-uploader"]?.click();
    },

    /**
     * @Description
     * This action is used to assign a file to imageSrc / videoSrc variable
     *  **/
    onFileChanged(e) {
      if (
        e?.target?.files[0] &&
        e?.target?.files[0]?.size < this.fileSizeLimit
      ) {
        this.selfieCameraBlob = e?.target?.files[0];
        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("upload-file").files[0]);
        oFReader.onload = (oFREvent) => {
          this.imageSrc = oFREvent.target.result;
          this.showRetry = true;
        };
      } else {
        showSimpleErrorMessage("image size cannot be greater than 3 mb.");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.searchResultInterval);
    clearTimeout(this.setTimeoutInterval);
  },
};
</script>
  
<style scoped>
.container {
  margin-top: 2rem;
}
.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}
.sub-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}
.age-main-container {
  padding: 40px;
}
.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.age-alert:deep(.v-alert__wrapper) {
  align-items: start;
}
.age-alert:deep(.v-alert__content) {
  text-align: justify;
  padding-right: 20px;
}
.age-verification-card {
  height: 300px;
  padding: 0px 16px 16px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.age-verification-card:deep(.v-card__text) {
  padding: 10px;
}
.ocr-result-card {
  max-height: 450px;
  overflow-y: scroll;
  border-radius: 10px;
  background: #f7f8f9;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.09),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.loader-div {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.card-title {
  display: flex;
  justify-content: center;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding: 0px 16px 8px 16px;
}
.card-body {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  overflow: hidden;
}
.card-body-mobile {
  height: auto;
}
.card-footer {
  display: flex;
  justify-content: center;
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.file-limit-text {
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.vertical-divider {
  width: 5px;
  height: 20px;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
}
.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
  cursor: pointer;
}
.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}
.preview-img {
  width: 100%;
  max-height: 210px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border-radius: 6px;
}
.preview-img-mobile {
  width: 250px;
}
.result-btns {
  column-gap: 16px;
}
.result-btns .view-result {
  display: flex;
  column-gap: 4px;
  color: #292b30;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.result-btns .try-more {
  display: flex;
  column-gap: 4px;
  width: 110px;
  height: 32px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.main-heading:deep(.info-icon) {
  font-size: 16px;
}
.selfie-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.step-retry {
  width: 100%;
  display: flex;
  justify-content: end;
}
.card-retry {
  color: #213cc8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.no-record {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-type {
  position: absolute;
  bottom: 18px;
}
.opacity-down {
  opacity: 0.5;
}
.button-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.copy-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>

<style>
.menu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.menu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.menu-custom-class .v-list-item__action {
  margin: 0px;
}

.menu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.search-filters .v-input__slot {
  min-height: 30px !important;
}

.search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  transform: none !important;
}

.search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}
</style>